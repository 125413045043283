import React from "react";
import { useNavigate } from "react-router-dom";
import LinkedinSvg from "../svg/LinkedinSvg";
import FacebookSvg from "../svg/FacebookSvg";
import InstagramSvg from "../svg/InstagramSvg";
import YoutubeSvg from "../svg/YoutubeSvg";
import { Column, IFooter, Links } from "../../globalTypes";
import { getLink } from "../functions/getLink";
import { footerWrapper } from "../../pages/Modules/CustomStyles";

interface Props {
  data: IFooter;
  hasCallToActionModule?: boolean;
}

const Footer = ({ data, hasCallToActionModule }: Props) => {
  const navigate = useNavigate();
  const renderSocialIcon = (type: string) => {
    switch (type) {
      case "LinkedIn":
        return <LinkedinSvg />;
      case "YouTube":
        return <YoutubeSvg />;
      case "Facebook":
        return <FacebookSvg />;
      case "Instagram":
        return <InstagramSvg />;
      default:
        return null; // Handle the case when the type is unknown or not found
    }
  };

  return (
    <div className="relative z-10 w-full bg-blue-light p-0">
      <div className={footerWrapper(hasCallToActionModule)}>
        <div className="flex w-full flex-col justify-between px-10 lg:mx-auto lg:w-[1000px] lg:flex-row xl:w-container xl:px-0 ">
          <div className="w-full lg:w-[45%]">
            <div className="w-full pb-[30px] text-3xl font-bold text-blue-dark lg:w-[80%] lg:text-50">
              {data?.heading}
            </div>
            <p className="w-full pb-[40px] text-gray-secondary lg:w-[80%]">
              {data?.copyrightNotice}
            </p>

            <div className="hidden w-full pb-40 lg:block lg:w-[80%]">
              <div className="-ml-4 flex justify-between">
                {data.socialLinks.map((link, index) => (
                  <a key={index} href={link.url} target={link.target}>
                    {renderSocialIcon(link.type)}
                  </a>
                ))}
              </div>
            </div>

            <div className="hidden items-center text-gray-secondary lg:flex">
              {data.legalLinks.map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    const linkURL = getLink(link.url);
                    navigate(linkURL);
                  }}
                  className="relative flex justify-center pl-3"
                >
                  {link.label}{" "}
                  {index < data.legalLinks.length - 1 && (
                    <span className="absolute -right-1.5 h-full border-l-[1px] border-gray-secondary" />
                  )}
                </button>
              ))}
            </div>
          </div>

          <div className="flex w-full flex-col-reverse justify-between pt-4 lg:w-[48%] lg:flex-row xl:w-[43%] ">
            <div className="mr-1 w-1/4 lg:mr-[70px]">
              {data?.columns.map((col: Column, index) => {
                if (col.heading.length === 0) {
                  return (
                    <div key={index}>
                      {col.links.map((item: Links, index) => (
                        <button
                          className="block pb-[20px] font-roboto text-lg font-bold text-blue-dark"
                          key={index}
                          onClick={() => {
                            const linkURL = getLink(item?.url);
                            navigate(linkURL);
                          }}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  );
                } else {
                  return <div className="hidden" key={index}></div>;
                }
              })}
            </div>

            <div className="grid-rows-auto grid h-auto grid-cols-2 gap-x-4 justify-self-end xs:w-full md:gap-x-[60px] lg:w-3/4">
              {data?.columns.map((col: Column, index) => {
                if (col.heading.length === 0) {
                  return <div className="hidden w-0" key={index}></div>;
                } else {
                  return (
                    <div key={index} className="w-full pb-[30px] lg:pb-0">
                      <div className="w-full">
                        <span className="break-words pb-[20px] text-left font-roboto text-lg font-bold text-blue-dark">
                          {col.heading}
                        </span>
                      </div>
                      {col?.links.map((item: Links, index) => (
                        <button
                          className="block break-words pt-[20px] text-left font-roboto text-base text-blue-dark xl:text-lg"
                          key={index}
                          onClick={() => {
                            const linkURL = getLink(item?.url);
                            navigate(linkURL);
                          }}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center lg:hidden">
        <div className="flex w-[90%] justify-between">
          {data.socialLinks.map((link, index) => (
            <a key={index} href={link.url} target={link.target}>
              {renderSocialIcon(link.type)}
            </a>
          ))}
        </div>
        <div className="flex justify-center text-gray-secondary">
          {data.legalLinks.map((link, index) => (
            <div
              className="flex items-baseline text-xs xl:text-base"
              key={index}
            >
              <button
                onClick={() => {
                  const linkURL = getLink(link.url);
                  navigate(linkURL);
                }}
              >
                {link.label}{" "}
                {index < data.legalLinks.length - 1 && (
                  <span className="xl:-pl-5 mt-0 px-[7px]">|</span>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
