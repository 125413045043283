import React from "react";
import Background3Image from "../../components/svg/Background3Image";
import { IContentModules, Quotes } from "../../globalTypes";
import FullCircleRadial from "../../components/svg/FullCircleRadial";
import {
  customModuleStyle,
  testimonialsModuleCarouselStyle,
} from "./CustomStyles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Keyboard } from "swiper/modules";

interface IProps {
  data: IContentModules;
  pageTemplate: string;
}
const TestimonialsModule = ({ data, pageTemplate }: IProps) => {
  return (
    <div
      className={`${customModuleStyle(
        data?.withBackground,
      )} relative md:px-[40px] xl:pb-[85px]`}
    >
      {pageTemplate === "Homepage" ? (
        <Background3Image />
      ) : (
        <FullCircleRadial />
      )}
      <div className="mx-4 flex flex-col lg:mx-auto xl:w-[1260px] xl:flex-row">
        <svg
          width="70"
          height="35"
          viewBox="0 0 70 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0L34.9999 35H0V0Z" fill="#DDE0FF" />
          <path d="M35 0L69.9999 35H35V0Z" fill="#DDE0FF" />
        </svg>
        <div className={testimonialsModuleCarouselStyle(pageTemplate)}>
          {data?.quotes?.length && data?.quotes?.length > 1 && (
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              grabCursor={true}
              pagination={{ clickable: true }}
              modules={[Keyboard, Pagination]}
              className="w-full lg:w-[90%]"
            >
              {data?.quotes?.map((quote, index) => (
                <SwiperSlide
                  className="mx-auto flex h-[80%] w-[860px] flex-col items-center justify-center"
                  key={index}
                >
                  <Context quote={quote} key={quote?.authorName} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {data?.quotes?.length && data?.quotes?.length === 1 && (
            <Context quote={data?.quotes[0]} />
          )}
        </div>
        <svg
          className="relative bottom-0 right-0 ml-auto xl:-bottom-[15rem]"
          width="70"
          height="35"
          viewBox="0 0 70 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 35L34.9999 0H0V35Z" fill="#DDE0FF" />
          <path d="M35 35L69.9999 0H35V35Z" fill="#DDE0FF" />
        </svg>
      </div>
    </div>
  );
};

interface ICProps {
  quote?: Quotes;
}

const Context = ({ quote }: ICProps) => {
  return (
    <div className="px-10 py-6 text-blue-dark">
      <p className="pb-[50px] text-xl text-gray-dark lg:text-3xl">
        {quote?.content}
      </p>
      <p className="pb-[10px] text-xl font-bold text-blue-dark xl:pt-0">
        {quote?.authorName}
      </p>
      <p className="whitespace-wrap text-sm text-gray-secondary xl:text-lg">
        {quote?.authorDesignation}
      </p>
    </div>
  );
};

export default TestimonialsModule;
