import React, { useMemo } from "react";
import { FeatureBlocks, IContentModules } from "../../globalTypes";
import Card from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";
import { getLink } from "../../components/functions/getLink";
import {
  customModuleStyle,
  darkButtonStyle,
  keyFeaturesBlockModuleCardsOrder,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const KeyFeaturesBlockModule = ({ data }: IProps) => {
  const navigate = useNavigate();
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  const cardWidth = useMemo(() => {
    if (data) {
      if (data?.featureBlocks) {
        const numberOfCards = data?.featureBlocks?.length;
        const singleCardWidth = 100 / numberOfCards;
        return singleCardWidth;
      }
    }
  }, [data]);

  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[10px] xl:px-0`}
    >
      <div className="rounded-3xl border border-primary-stroke px-[26px] py-10 lg:mx-auto lg:border-0 lg:py-0 xl:w-[1230px] xl:p-0">
        <div className="mb-20 md:mb-32">
          <h2 className="pb-[10px] font-roboto text-25 font-bold lg:pb-[30px] lg:text-40">
            {data?.heading}
          </h2>
          <p className="font-roboto text-20/26 text-gray lg:text-3xl">
            {data?.subHeading}
          </p>
        </div>
        <div className="-mx-6 h-1/2 rounded-3xl border-primary-stroke px-[26px] lg:border lg:py-6 xl:p-10">
          <div
            className={keyFeaturesBlockModuleCardsOrder(cardWidth)}
            style={{ marginTop: "-10%" }}
          >
            {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
              <Card
                key={index}
                image={item.icon ? baseUrl + item.icon.url : ""}
                customClassNames={"lg:mr-[30px] lg:mb-0 bg-white"}
                customClassNameParagraph="lg:text-lg text-base"
                title={item.heading}
                paragraph={item.description}
                link={item.link}
                customStyle={
                  cardWidth && cardWidth < 21
                    ? "w-[140px] sm:w-full px-[20px] lg:px-[30px] py-[30px] rounded-[30px]"
                    : "w-full p-[30px] rounded-[40px]"
                }
                isTextSmaller={cardWidth && cardWidth < 21 ? true : false}
              />
            ))}
          </div>
          <div className="mt-[30px] xl:mt-[50px]">
            <div className="font-roboto text-gray-border">
              <div
                className="business-specific-container pb-[30px] text-gray"
                dangerouslySetInnerHTML={
                  { __html: data?.description || "" } as { __html: string }
                }
              />
            </div>
            <button
              onClick={() => {
                const linkURL = getLink(data?.link?.url || "");
                navigate(linkURL);
              }}
              className={`${darkButtonStyle} w-full px-8 py-2.5 lg:px-9 xl:w-fit`}
            >
              {data?.link?.label}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeaturesBlockModule;
