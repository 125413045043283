import React, { Dispatch, SetStateAction, useMemo } from "react";
import { ICard } from "../../globalTypes";
import Check from "../svg/Check";
import Info from "../svg/info-circle.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  pricingModuleCardButtonStyle,
  pricingModuleCardStyle,
} from "../../pages/Modules/CustomStyles";

interface Props {
  data: ICard;
  isAnnually?: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setPlanOpen: Dispatch<SetStateAction<number | null>>;
  ctaLinkLabel?: string;
  planOpen: number | null;
  formOpen: boolean;
  getStartedOpen: boolean;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setGetStartedOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const PricingModuleCard = ({
  data,
  isAnnually,
  setIsDialogOpen,
  setPlanOpen,
  ctaLinkLabel,
  setFormOpen,
  setGetStartedOpen,
}: Props) => {
  const price = useMemo(() => {
    const priceText = isAnnually
      ? data?.priceTextAnnually
      : data?.priceTextMonthly;
    const priceArr = priceText?.split(" /");
    return (
      <>
        {priceArr?.length && (
          <>
            <span className="text-3xl font-semibold text-blue-dark lg:max-xl:text-2xl">
              {priceArr[0]}
            </span>{" "}
            {priceArr[1] && (
              <span className="text-lg font-semibold text-blue-dark">
                /{priceArr[1]}
              </span>
            )}
          </>
        )}
      </>
    );
  }, [data]);

  return (
    <div className="flex w-full flex-col lg:w-[20%] lg:text-sm xl:text-lg">
      {data?.label && data?.label?.length > 0 ? (
        <div className="rounded-t-[50px] bg-blue px-8 py-4 font-semibold text-white">
          {data?.label}
        </div>
      ) : (
        <div className="h-auto lg:h-[60px]"></div>
      )}
      <div className={pricingModuleCardStyle(data?.label)}>
        <div className="my-4">
          <h2 className="pb-[5px] text-2xl font-bold text-blue xl:text-[35px] ">
            {data?.planName}
          </h2>
          <div className="h-auto font-roboto text-sm text-gray-secondary lg:h-[20px]">
            <span>{data?.subHeading}</span>
            <img
              src={Info}
              alt="info icon"
              data-tooltip-id="tooltip-info"
              className="ml-2 inline"
            />
            <ReactTooltip
              id="tooltip-info"
              place="bottom"
              content={data?.subHeadingInfo}
            />
          </div>
        </div>

        <div key={ctaLinkLabel}>
          <button
            onClick={() => {
              setIsDialogOpen(true);
              if (data?.id) {
                setPlanOpen(data?.id);

                if (!data?.link) {
                  setFormOpen(true);
                  setGetStartedOpen(false);
                } else {
                  setFormOpen(false);
                  setGetStartedOpen(true);
                }
              }
            }}
            className={pricingModuleCardButtonStyle(data?.label)}
          >
            {ctaLinkLabel}
          </button>
        </div>

        <div>
          {price}

          <div className="h-auto pb-[40px] text-sm text-gray-secondary lg:h-14 lg:pb-4">
            {data?.priceSubText}
          </div>
        </div>

        <div>
          {data?.featureList?.map((item, index) => (
            <div key={index}>
              {item.isChecked ? (
                <div className="flex items-start justify-start p-2">
                  <div className="pr-4 pt-1">
                    <Check />
                  </div>
                  <div className="text-sm text-gray">{item?.text}</div>
                </div>
              ) : (
                <div className="flex items-start justify-start p-2">
                  <div className="w-[13px] pr-8"></div>
                  <div className="text-sm text-gray-border">{item?.text}</div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="pt-[40px]">
          {data?.additionalFeature ? (
            <div className="flex items-start justify-start px-2 text-gray">
              <div className="-mt-[6px] pr-[10px] text-[30px] font-bold">+</div>
              <div
                dangerouslySetInnerHTML={
                  { __html: data?.additionalFeature || "" } as {
                    __html: string;
                  }
                }
                className="dangerouslySetInnerHTML text-xl"
              />
            </div>
          ) : (
            <div className="h-14"></div>
          )}
        </div>

        {data?.label && data?.label?.length > 0 && <div className="h-8"></div>}
      </div>
    </div>
  );
};

export default PricingModuleCard;
