import React from "react";
import { IContentModules } from "../../globalTypes";
import location from "../../components/svg/location.svg";
import phone from "../../components/svg/phone.svg";
import email from "../../components/svg/email.svg";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const ContactsModule = ({ data }: IProps) => {
  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        <div className="font-roboto text-2xl font-bold text-blue-dark xl:text-40">
          {data?.heading}
        </div>
        <div className="flex h-auto w-full flex-col items-start justify-between lg:mx-auto lg:flex-row lg:flex-wrap">
          {data?.cards.map((card, index) => {
            return (
              <div key={index} className="w-auto lg:w-[45%]">
                <div className="pb-2 pt-[30px] text-xl font-bold text-blue-dark xl:pt-[50px] xl:text-3xl">
                  {card.heading}
                </div>
                {card.subHeading && (
                  <div className="h-auto text-xl font-semibold text-gray">
                    {card.subHeading}
                  </div>
                )}
                {card.location && (
                  <div className="flex h-auto items-center justify-start py-1 text-base text-gray xl:text-xl">
                    <img
                      src={location}
                      alt="location"
                      className="mr-2 h-4 w-4 xl:mr-4 xl:h-[26px] xl:w-[24px]"
                    />
                    {card.location}
                  </div>
                )}
                {card.emailAddress && (
                  <div className="flex h-auto items-center justify-start py-1 text-base text-gray xl:text-xl">
                    <img
                      src={email}
                      alt="email"
                      className="mr-2 h-4 w-4 xl:mr-4 xl:h-[26px] xl:w-[24px]"
                    />
                    {card.emailAddress}
                  </div>
                )}
                {card.phoneNumber && (
                  <div className="flex h-auto items-center justify-start py-1 text-base text-gray xl:text-xl">
                    <img
                      src={phone}
                      alt="phone"
                      className="mr-2 h-4 w-4 xl:mr-4 xl:h-[26px] xl:w-[24px]"
                    />
                    {card.phoneNumber}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactsModule;
