import React from "react";
import { Props } from "./types";
import classnames from "classnames";
import LinkComp from "../LinkComp/LinkComp";
import { cardHeadingStyle } from "../../pages/Modules/CustomStyles";

const Card = ({
  image,
  title,
  paragraph,
  link,
  customClassNames,
  customClassNameParagraph,
  customStyle,
  isTextSmaller,
}: Props) => {
  return (
    <div
      className={classnames(
        "z-20 flex flex-col border border-primary-stroke",
        customClassNames,
        customStyle,
      )}
    >
      <div>
        {image && (
          <img
            src={image}
            alt="Card Image"
            style={{ width: "54px", height: "44px", marginBottom: "20px" }}
          />
        )}
        <h3 className={cardHeadingStyle(isTextSmaller)}>{title}</h3>
      </div>
      {paragraph && (
        <div
          className={classnames(
            customClassNameParagraph,
            "pt-5 font-roboto text-gray",
          )}
        >
          <div
            dangerouslySetInnerHTML={
              { __html: paragraph || "" } as {
                __html: string;
              }
            }
          />
        </div>
      )}

      <div className="mt-auto w-fit">
        {link && (
          <LinkComp
            linkUrl={link?.url}
            target={link?.target}
            label={link.label}
            isExternal={link?.isExternal}
            customStyle="read-more-btn flex items-center justify-start pt-5 font-medium text-blue"
            customLabelStyle="read-more-blue-hover"
            shouldShowArrow={true}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
