import classNames from "classnames";
import { IBlogList, Link } from "../../globalTypes";

export function customModuleStyle(withBackground?: boolean) {
  return classNames("w-full lg:py-[50px] py-[40px]", {
    "bg-blue-light": withBackground,
    "bg-white": !withBackground,
  });
}

export const customCardsOrderStyle = classNames(
  "grid lg:grid-cols-2 xs:grid-cols-1 gap-x-[30px] xl:gap-y-[50px] gap-y-[30px]",
);

export function topFeatureModuleCustomWrapper(pageTemplate?: string) {
  return classNames("xl:w-container lg:w-full lg:mx-auto", {
    "md:-mt-[200px] -mt-32": pageTemplate === "Homepage",
  });
}

export function topFeatureModuleCardWrapper(pageTemplate?: string) {
  return classNames(
    "xl:w-container grid-template xl:gap-y-[50px] gap-y-[30px] gap-x-[30px] xl:px-0 px-[40px]",
    {
      "blue-background": pageTemplate === "Homepage",
      "": pageTemplate !== "Homepage",
    },
  );
}

export function topFeatureModuleHeadingStyle(pageTemplate?: string) {
  return classNames(
    "relative z-20 xl:text-40 text-25 font-roboto font-bold px-[30px] xl:px-0 mb-[50px]",
    {
      "text-white md:text-blue-dark": pageTemplate === "Homepage",
      "text-blue-dark": pageTemplate === "ContentPage",
    },
  );
}

export function testimonialsModuleCarouselStyle(pageTemplate?: string) {
  return classNames("relative z-10 h-auto md:w-[80%] 2xl:w-[87%] 3xl:w-[90%]", {
    "w-[90%]": pageTemplate === "Homepage",
    "w-full": pageTemplate !== "Homepage",
  });
}

export function featuredBlockModuleCustomOrder(isImageOnLeft?: boolean) {
  return classNames(
    "w-full flex justify-between lg:flex-row items-start xl:flex-row flex-col xl:pr-0",
    {
      "lg:flex-row-reverse xl:flex-row-reverse flex-col": isImageOnLeft,
    },
  );
}

export function keyFeaturesBlockModuleCardsOrder(cardWidth?: number) {
  return classNames(
    "grid lg:grid-flow-col lg:gap-[30px] gap-[20px] lg:auto-cols-fr",
    {
      "grid-cols-2 lg:grid-flow-col lg:auto-cols-fr":
        cardWidth && cardWidth < 21,
    },
  );
}

export function specialBannerModuleBg(pageTemplate?: string) {
  return classNames("", {
    "2xl:block hidden bg-radial-courses":
      pageTemplate === "CourseLandingPage" ||
      pageTemplate === "CoursesGroupLandingPage",
  });
}

export function simpleBannerModuleCustomOrder(isImageOnLeft?: boolean) {
  return classNames(
    "w-full flex justify-between lg:flex-row items-start xl:flex-row flex-col flex-col-reverse w-full ",
    {
      "lg:flex-row-reverse xl:flex-row-reverse flex-col": !isImageOnLeft,
    },
  );
}

export function simpleBannerModuleImage(isImageOnLeft?: boolean) {
  return classNames("w-full lg:w-1/2", {
    "lg:pl-[30px] pt-[30px] lg:pt-0": !isImageOnLeft,
    "lg:pr-[30px]": isImageOnLeft,
  });
}

export function cardHeadingStyle(isTextSmaller?: boolean) {
  return classNames("font-bold text-blue-dark font-roboto", {
    "text-lg": isTextSmaller,
    "xl:text-3xl text-xl": !isTextSmaller,
  });
}

export function pricingModuleCardStyle(label?: string) {
  return classNames("px-4 py-4 flex flex-col bg-white lg:bg-transparent", {
    "rounded-3xl": !label,
    "rounded-b-3xl  lg:shadow-lg shadow:none": label,
  });
}

export const darkButtonStyle = classNames(
  "whitespace-nowrap flex justify-center items-center rounded-sm border border-blue bg-blue text-white hover:bg-blue-hover hover:border-blue-hover",
);

export const lightButtonStyle = classNames(
  "whitespace-nowrap flex justify-center items-center bg-white text-blue border rounded-sm border-blue hover:border-blue-hover hover:text-blue-hover",
);

export function pricingModuleCardButtonStyle(label?: string) {
  return classNames("my-[40px]", {
    "flex justify-center items-center bg-blue text-white rounded-sm py-3 px-7 hover:bg-blue-hover":
      label,
    "flex justify-center items-center bg-white text-blue py-3 px-7 border rounded-sm border-blue hover:border-blue-hover hover:text-blue-hover":
      !label,
  });
}

export function pricingInteractivityCardStyle(label?: string) {
  return classNames("border border-gray-light px-6 py-4 flex flex-col grow", {
    "rounded-3xl": !label,
    "rounded-b-3xl": label,
  });
}

export const pricingInteractivityCardSectionStyle = classNames(
  "border-b border-gray-light py-[30px]",
);

export const gridCardsOrderStyle = classNames(
  "grid lg:grid-cols-3 xs:grid-cols-1 gap-x-[30px] xl:gap-y-[50px] gap-y-[30px]",
);

export const courseDescriptionModuleInfoStyle = classNames(
  "lg:w-[50%] w-full flex justify-start items-center lg:my-5 my-4",
);

export const courseDescriptionModulelabelStyle = classNames(
  "font-bold text-xl text-blue-dark ml-4",
);

export function imageModuleWrapper(isBigScreen?: boolean) {
  return classNames("h-auto mx-auto flex justify-center items-start", {
    "max-w-[860px]": isBigScreen,
    "w-[300px] h-[206px] ": !isBigScreen,
  });
}

export function pricingModuleDialogStyle(
  formOpen?: boolean,
  getStartedOpen?: boolean,
) {
  return classNames(
    "h-[95%] justify-center items-center flex  fixed inset-0 z-40 outline-none focus:outline-none lg:translate-y-[-50px] translate-y-[-20px] rounded-xl transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[95%]",
    {
      "md:w-[660px] w-full px-[30px] ": formOpen,
      "md:w-[560px] w-full px-[30px]": getStartedOpen,
    },
  );
}

export function pricingModuleFormDialog(respData?: boolean) {
  return classNames(
    "relative overflow-y-auto py-[20px] max-h-[75vh] overflow-y-auto text-center",
    {
      "px-[16px]": respData,
      "px-5": !respData,
    },
  );
}

export function verticalCarouselStyle(
  isBigScreen?: boolean,
  isSmallScreen?: boolean,
) {
  return classNames(
    "w-full bg-light-blue grid  grid-rows-1 gap-[11.54px] xl:gap-x-5",
    {
      "grid-cols-5 pt-[50px]": isBigScreen,
      "grid-cols-3 pt-[30px]": isSmallScreen,
      "grid-cols-4 pt-[50px]": !isSmallScreen && !isBigScreen,
    },
  );
}

export const customerStoryDescModuleWrapper = classNames(
  "w-full grid xl:grid-cols-3 md:grid-cols-1 gap-x-[30px] xl:gap-y-[50px] gap-y-[30px] xl:pt-0 pt-[30px]",
);

export const topBlogsModuleModuleStyle = classNames(
  "w-full px-10 py-[40px] lg:py-[50px] xl:mx-auto xl:w-container xl:px-0",
);

export const topBlogsModuleCardWrapper = classNames(
  "flex w-full flex-col items-start justify-between xl:flex-row lg:mx-auto xl:w-container",
);

export function topBlogsModuleCardLeft(blogsList: IBlogList[] | undefined) {
  return classNames({
    "w-full": blogsList?.length === 1,
    "lg:w-[58%] lg:mr-[40px] w-full": blogsList && blogsList?.length > 1,
  });
}

export const topBlogsModuleCardRight = classNames(
  "-mx-10 bg-blue-light xl:w-[70%] xl:bg-transparent xl:px-0 xl:py-0",
);

export function blogSButtonStyle(hasNextPage?: boolean) {
  return classNames("flex w-full items-center justify-center", {
    hidden: !hasNextPage,
  });
}

export function videoCardThumbnail(hasImg?: boolean) {
  return classNames("w-full h-80 object-cover overflow-hidden", {
    "bg-gray-200": !hasImg,
  });
}

export const textAreaStyle = classNames(
  "flex justify-between items-center bg-white text-gray text-base rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border border-gray-light mb-5",
);

export const selectFieldStyle = classNames(
  "flex justify-between items-center bg-white text-gray text-base rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border border-gray-light",
);
export function selectInputStyle(hasError?: boolean) {
  return classNames({ "border border-red": hasError });
}

export const selectOptionStyle = classNames(
  "absolute bg-white text-gray text-base rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 border border-gray-light",
);

export function inputFieldStyle(hasError?: boolean) {
  return classNames(
    "bg-white text-gray text-base rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
    {
      "border border-red": hasError,
      "border border-gray-light": !hasError,
    },
  );
}

export function eCourseCardStyle(customStyle?: string) {
  return classNames(
    customStyle,
    "border border-gray-light rounded-[40px] bg-white w-full relative overflow-hidden card-hover",
  );
}

export function blogCardStyle(customStyle?: string, moduleName?: string) {
  return classNames(
    customStyle,
    "mb-[20px] w-full relative overflow-hidden card-hover",
    {
      "lg:h-auto":
        moduleName === "BlogItemsByCategoryModule" ||
        moduleName === "ResourcesModule",
      "h-auto": moduleName === "TopBlogsModule",
    },
  );
}

export function blogCardLink(isImgOnRight?: boolean) {
  return classNames("lg:h-full flex", {
    "flex-col": !isImgOnRight,
    "flex-row-reverse justify-between items-start": isImgOnRight,
  });
}

export function blogCardImage(
  isImgOnRight?: boolean,
  isSmallScreen?: boolean,
  hasImg?: boolean,
) {
  return classNames(
    "hover-img absolute object-cover transition-all duration-500 ease-out",
    {
      "w-full h-32 pb-3": isImgOnRight && !isSmallScreen,
      "w-[140px] h-[140px] pb-3": isImgOnRight && isSmallScreen,
      "w-full h-[370px] mb-[20px]": !isImgOnRight,
      "w-full h-[370px] p-3 bg-gray-200 animate-pulse":
        !isImgOnRight && !hasImg,
      "w-full h-32 p-3  bg-gray-200 animate-pulse": isImgOnRight && !hasImg,
    },
  );
}

export const blogCardLabel = classNames(
  "text-gray-border text-lg textEllipsis3Lines pb-[10px]",
);

export function blogCardHeading(isImgOnRight?: boolean) {
  return classNames("mb-[10px] text-blue-dark pr-[20px] textEllipsis3Lines", {
    "xl:text-xl text-lg": isImgOnRight,
    "text-2xl": !isImgOnRight,
  });
}

export function blogCardSubmoduleStyle1(isImgOnRight?: boolean) {
  return classNames("relative", {
    "lg:w-[170px] w-[140px] pt-[30px] h-[140px] overflow-hidden": isImgOnRight,
    "h-[370px] overflow-hidden": !isImgOnRight,
  });
}

export function blogCardSubmoduleStyle2(isImgOnRight?: boolean) {
  return classNames("", {
    "w-7/12 pt-[30px]": isImgOnRight,
  });
}

export function footerWrapper(hasCallToActionModule?: boolean) {
  return classNames("w-full pb-[50px]", {
    "pt-[130px]": hasCallToActionModule,
    "pt-[50px]": !hasCallToActionModule,
  });
}

export function heroWrapper(
  showRectangle?: boolean,
  backgroundOption?: string,
  disableMinimumHeroHeight?: boolean,
  hasImage?: boolean,
) {
  return classNames("relative pb-[40px] xl:pb-[50px]", {
    "lg:border-l-[10px] border-l-[5px] border-blue": !showRectangle,
    "bg-gray-light": backgroundOption === "purple",
    "bg-blue-light": backgroundOption === "light-purple",
    "lg:min-h-[480px]": !disableMinimumHeroHeight && hasImage,
    "lg:min-h-[280px]": disableMinimumHeroHeight || !hasImage,
  });
}

export function heroContentWrapper(pageTemplate?: string) {
  return classNames("relative mx-auto ", {
    "max-w-[1400px]":
      pageTemplate !== "CoursePage" && pageTemplate !== "CoursesGroupPage",
    "max-w-[2000px]":
      pageTemplate === "CoursePage" || pageTemplate === "CoursesGroupPage",
  });
}

export function heroTextContainer(
  pageTemplate?: string,
  hasImage?: boolean,
  description?: string,
  subHeading?: string,
) {
  return classNames("lg:w-full", {
    "relative h-auto h-min-[400px]  px-4":
      pageTemplate !== "Homepage" && hasImage,
    "h-auto pb-[200px]  px-4":
      pageTemplate === "Homepage" &&
      hasImage &&
      ((description && description.length) || subHeading?.length),
    "p-0": pageTemplate !== "Homepage" && !hasImage,
  });
}

export function heroTextSubcontainer(
  pageTemplate?: string,
  hasImage?: boolean,
) {
  return classNames("mx-0 xl:mx-0", {
    "w-[300px] lg:w-1/2 3xl:w-[60%] md:mr-20 md:w-[470px]  ":
      pageTemplate === "Homepage",
    "lg:w-[770px] w-full": pageTemplate !== "Homepage" && !hasImage,
    "lg:w-[630px] w-full": pageTemplate === "CustomerStoryPage",
    "lg:w-[670px] w-full":
      pageTemplate !== "Homepage" &&
      hasImage &&
      pageTemplate !== "CustomerStoryPage",
  });
}

export function heroHeadingStyle(pageTemplate?: string) {
  return classNames(
    "font-roboto relative z-20 text-30 font-bold text-blue-dark lg:w-full xl:text-6xl xl:leading-[64px] h-auto",
    {
      "w-full": pageTemplate === "Homepage",
    },
  );
}

export function heroSubHeadingStyle(pageTemplate?: string) {
  return classNames("w-full relative z-20 ", {
    "lg:w-[570px]": pageTemplate === "HomePage",
    "lg:w-full": pageTemplate !== "HomePage",
  });
}

export function heroRectangleImage(
  showRectangle?: boolean,
  rectangleIsLeft?: boolean,
) {
  return classNames({
    customLeftShape: showRectangle && rectangleIsLeft,
    customRightShape: showRectangle && !rectangleIsLeft,
  });
}

export function heroImage(pageTemplate?: string) {
  return classNames(
    "lg:flex hidden flex-end items-end absolute z-20 lg:w-1/3 w-[55%] lg:right-0",
    {
      "xl:w-[45%] top-[60px]":
        pageTemplate === "CoursePage" || pageTemplate === "CoursesGroupPage",
      "xl:w-[40%] top-24":
        pageTemplate !== "CoursePage" && pageTemplate !== "CoursesGroupPage",
    },
  );
}

export const heroHomeImageStyle = classNames(
  "lg:flex hidden flex-end items-end absolute z-20 w-1/2 2xl:w-[800px] -right-[15px] top-0",
);

export const heroCustomerImageStyle = classNames(
  "lg:flex hidden flex-end items-end absolute z-20 lg:-bottom-14 lg:-right-[350px] w-full lg:w-[700px] xl:-right-[240px] 2xl:w-[900px] 3xl:w-[55%] 4xl:-right-[350px] 2xl:-right-[200px] right-0 lg:-right-20 3xl:-right-18",
);

export function rightSectionStyle(
  links?:
    | {
        color: string;
        link: Link;
      }[]
    | undefined,
) {
  return classNames("xl:pr-9 px-0 text-white self-start", {
    "lg:w-[860px] 2xl:w-[80%]": links && links?.length === 1,
    "lg:w-[660px] 2xl:w-full": links && links?.length === 2,
    "lg:w-auto": !links || links?.length === 0,
  });
}
