import "../../assets/scss/header.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import LogoSvg from "../svg/LogoSvg";
import DropDownIndicator from "../svg/DropDownIndicator";
import HamburgerSvg from "../svg/HamburgerSvg";
import { HeaderLinks, IHeader, NavigationItems } from "../../globalTypes";
import { getLink } from "../functions/getLink";
import {
  darkButtonStyle,
  lightButtonStyle,
} from "../../pages/Modules/CustomStyles";
import { HomeLinks } from "../../consts/links";
interface Props {
  data: IHeader;
}

interface ILink extends NavigationItems {
  index: number;
  isOpen?: boolean;
}

const Header = ({ data }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [mobileLinks, setMobileLinks] = useState<ILink[] | []>([]);
  const [desktopLinks, setDesktopLinks] = useState<ILink[] | []>([]);
  const [isLangDesktopOpen, setIsLangDesktopOpen] = useState<boolean>(false);
  const [isLangMobileOpen, setIsLangMobileOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOnClickOutsideDesktop = () => {
    const closeSublinks = mobileLinks.map((l) => {
      return { ...l, isOpen: false };
    });
    setDesktopLinks(closeSublinks);
  };

  const handleOnClickOutsideMobile = () => {
    const closeSublinks = mobileLinks.map((l) => {
      return { ...l, isOpen: false };
    });
    setIsMobileMenuOpen(false);
    setMobileLinks(closeSublinks);
    // setIsLangMobileOpen(false)
  };

  const mobileRef = useRef(null);
  useOnClickOutside(mobileRef, handleOnClickOutsideMobile);
  const desktopRef = useRef(null);
  useOnClickOutside(desktopRef, handleOnClickOutsideDesktop);

  useEffect(() => {
    if (data?.navigationItems) {
      const newLinks = data?.navigationItems?.map((link, index) => {
        if (link?.columns?.length > 0) {
          return { ...link, index, isOpen: false };
        } else {
          return { ...link, index };
        }
      });
      setMobileLinks(newLinks);
      setDesktopLinks(newLinks);
    }
  }, [data]);

  const language = useMemo(() => {
    const pathname = location.pathname;
    const pathnameSplitted = pathname.split("/");
    const selectedLang = data.languageLinks.find((l) =>
      l.culture.includes(pathnameSplitted[1]),
    );
    return selectedLang ? pathnameSplitted[1].toLocaleUpperCase() : "EN";
  }, [data]);

  return (
    <>
      <div
        id="header"
        className="2lg:justify-center fixed left-0 right-0 top-0 z-30 flex h-[80px] w-full justify-between border-b border-primary-stroke bg-white py-1"
      >
        <div
          ref={desktopRef}
          className="2lg:justify-center mx-auto flex w-full justify-between px-[20px] font-roboto text-blue-dark xl:px-0"
        >
          <div
            className="mr-6 flex h-full cursor-pointer items-center lg:mr-8 2xl:mr-12"
            onClick={() => navigate(HomeLinks[language])}
          >
            <LogoSvg />
          </div>

          <div className="items-menu 2lg:flex hidden items-center font-semibold">
            {desktopLinks?.map((item: ILink, index) => (
              <div className="w-full text-xs xl:text-base" key={index}>
                <div
                  className={
                    item?.link?.isActive
                      ? "relative flex h-full items-center pr-6 text-blue hover:cursor-pointer 2xl:pr-8"
                      : "relative flex h-full items-center pr-6 text-blue-dark hover:cursor-pointer hover:text-blue-hover 2xl:pr-8"
                  }
                  onClick={() => {
                    if (item.columns?.length > 0) {
                      const oldLinks = [...mobileLinks].map((l) => {
                        return { ...l, isOpen: false };
                      });
                      const newLink = { ...item, isOpen: !item.isOpen };
                      oldLinks.splice(index, 1, newLink);
                      setDesktopLinks(oldLinks);
                      // setIsLangDesktopOpen(false);
                    } else {
                      const linkURL = getLink(item?.link?.url);
                      navigate(linkURL);
                    }
                  }}
                >
                  <span className="link flex h-4 items-center py-6">
                    {item.link?.label}
                    {item.columns?.length > 0 && <DropDownIndicator />}
                  </span>
                </div>
                {item?.isOpen && (
                  <div className="dropdown-content pt-[16.5px]">
                    <div className="dropdown-items flex justify-evenly border border-primary-stroke bg-white">
                      {item?.columns?.map((sublink, index) => (
                        <div key={index} className="px-4 py-2">
                          {sublink?.heading && (
                            <div className="text-md py-1 font-bold">
                              {sublink?.heading}
                            </div>
                          )}
                          {sublink?.links?.map((l, index) => (
                            <div
                              className={
                                l?.isActive
                                  ? "text-md py-1 font-normal text-blue hover:cursor-pointer"
                                  : "text-md py-1 font-normal hover:cursor-pointer hover:text-blue-hover"
                              }
                              onClick={() => {
                                const linkURL = getLink(l?.url);
                                navigate(linkURL);
                                setIsMobileMenuOpen(false);
                              }}
                              key={index}
                            >
                              {l.label}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* btns desktop */}
          <div className="2lg:flex hidden items-center justify-between text-xs xl:text-base">
            {data.links.map((l: HeaderLinks) => (
              <button
                key={l.link.label}
                onClick={() => {
                  const linkURL = getLink(l?.link?.url);
                  navigate(linkURL);
                }}
                className={
                  l.color === "dark"
                    ? `${darkButtonStyle} ml-[12px] mr-2.5 px-[30px] py-2.5`
                    : `${lightButtonStyle} px-[30px] py-2.5`
                }
              >
                {l.link.label}
              </button>
            ))}
          </div>

          <div
            className="link dropdown-div 2lg:flex relative hidden h-full items-center text-xs hover:cursor-pointer xl:text-base"
            onClick={() => {
              const oldLinks = [...desktopLinks].map((l) => {
                return { ...l, isOpen: false };
              });
              setDesktopLinks(oldLinks);
              setIsLangDesktopOpen((prev) => !prev);
            }}
          >
            <span className="link ml-[20px] flex h-full items-center py-6 font-semibold hover:text-blue">
              {language}
              <DropDownIndicator />
            </span>
            {isLangDesktopOpen && (
              <div className="dropdown-items w-min-[160px] absolute -left-9 top-[4.75rem] flex flex-col justify-evenly border border-t-0 border-primary-stroke bg-white px-4 py-1 2xl:-left-0">
                {data?.languageLinks.map((link) => (
                  <a
                    key={link.name}
                    className={
                      link?.isCurrent
                        ? "text-md py-1 font-normal text-blue hover:cursor-pointer"
                        : "text-md py-1 font-normal hover:cursor-pointer hover:text-blue"
                    }
                    onClick={() => {
                      const linkURL = getLink(link?.url);
                      navigate(linkURL);
                    }}
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            )}
          </div>

          <div
            id="hamburger"
            className="2lg:hidden flex items-center justify-between"
          >
            <button
              className="2lg:hidden mx-2"
              onClick={() => {
                setIsMobileMenuOpen((current) => !current);
              }}
            >
              <HamburgerSvg />
            </button>
          </div>
        </div>
      </div>

      {/* mobile navigation */}
      {isMobileMenuOpen && (
        <div
          ref={mobileRef}
          id="mobileNav"
          className="items-menu 2lg:hidden fixed left-0 top-[80px] z-50 flex w-full flex-col items-center  overflow-y-auto bg-blue-light font-semibold shadow-md"
        >
          {mobileLinks?.map((item: ILink, index) => (
            <div className="w-full" key={index}>
              <div
                className="flex w-full items-center justify-between px-12 py-4 hover:cursor-pointer hover:bg-gray-light"
                onClick={() => {
                  if (item.columns?.length > 0) {
                    const oldLinks = [...mobileLinks];
                    const newLink = { ...item, isOpen: !item.isOpen };
                    oldLinks.splice(index, 1, newLink);
                    setMobileLinks(oldLinks);
                  } else {
                    const linkURL = getLink(item?.link?.url);
                    navigate(linkURL);
                    setIsMobileMenuOpen(false);
                  }
                }}
              >
                {item.link?.label}
                {item.columns?.length > 0 && <DropDownIndicator />}
              </div>
              {item?.isOpen && (
                <div>
                  {item?.columns?.map((sublink, index) => (
                    <div key={index} className="px-16 py-2">
                      <div className="text-md py-1 font-bold">
                        {sublink?.heading}
                      </div>
                      {sublink?.links?.map((l, index) => (
                        <div
                          className="text-md py-1 font-normal hover:cursor-pointer hover:bg-gray-light"
                          onClick={() => {
                            const linkURL = getLink(l?.url);
                            navigate(linkURL);
                            setIsMobileMenuOpen(false);
                          }}
                          key={index}
                        >
                          {l.label}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className="w-full text-base">
            <div
              onClick={() => setIsLangMobileOpen((current) => !current)}
              className="flex w-full items-center justify-between px-12 py-4 hover:cursor-pointer hover:bg-gray-light"
            >
              {language}
              <div>
                <DropDownIndicator />
              </div>
            </div>
            {isLangMobileOpen && (
              <div className="px-16 py-2 text-base">
                {data?.languageLinks.map((link) => (
                  <div
                    key={link.name}
                    className="py-1 text-base font-normal hover:cursor-pointer hover:bg-gray-light"
                    onClick={() => {
                      const linkURL = getLink(link.url);
                      navigate(linkURL);
                      setIsLangMobileOpen(false);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    {link.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
