import React, { useMemo } from "react";
import { IHero } from "../../globalTypes";
import classnames from "classnames";
import getSymbolFromCurrency from "currency-symbol-map";
import LinkComp from "../LinkComp/LinkComp";
import {
  heroCustomerImageStyle,
  heroContentWrapper,
  heroHeadingStyle,
  heroRectangleImage,
  heroSubHeadingStyle,
  heroWrapper,
  heroHomeImageStyle,
  heroImage,
  heroTextSubcontainer,
  heroTextContainer,
  darkButtonStyle,
  lightButtonStyle,
} from "../../pages/Modules/CustomStyles";

interface Props {
  data?: IHero;
  pageTemplate: string;
}

const env = window._env_ ?? {}

const Hero = ({ data, pageTemplate }: Props) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  const heroStyle = classnames(
    "relative mx-auto h-full lg:pt-[100px] pt-[50px]",
    {
      "max-w-[1400px]":
        pageTemplate !== "Homepage" &&
        pageTemplate !== "CustomerStoryPage" &&
        pageTemplate !== "CoursePage" &&
        pageTemplate !== "CoursesGroupPage",
      "max-w-[2000px]":
        pageTemplate === "CoursePage" || pageTemplate === "CoursesGroupPage",
      "lg:w-[770px] w-full": !data?.image,
    },
  );

  function formatData(
    data: IHero | undefined,
    propertyName: string,
  ): string[] | undefined {
    type ObjectKey = keyof typeof data;

    if (data && data[propertyName as ObjectKey] != undefined) {
      const property: string = data[propertyName as ObjectKey];
      if (property.includes("\n")) {
        const stringArray = property.split("\n");
        return stringArray;
      }
      return [data[propertyName as ObjectKey]];
    }
  }

  const heading: string[] | undefined = useMemo(() => {
    return formatData(data, "heading");
  }, [data?.heading]);

  const subHeading: string[] | undefined = useMemo(() => {
    return formatData(data, "subHeading");
  }, [data?.subHeading]);

  const description = useMemo(() => {
    return formatData(data, "description");
  }, [data?.description]);

  return (
    <div
      className={heroWrapper(
        data?.showRectangle,
        data?.backgroundOption,
        data?.disableMinimumHeroHeight,
        !!data?.image,
      )}
    >
      <div
        className={heroRectangleImage(
          data?.showRectangle,
          data?.rectangleIsLeft,
        )}
      ></div>
      {pageTemplate === "Homepage" && (
        <div className={heroHomeImageStyle}>
          {data?.image?.url && (
            <img src={baseUrl + data?.image?.url} alt={data?.image?.altText} />
          )}
        </div>
      )}
      {pageTemplate === "CustomerStoryPage" && (
        <div className={heroCustomerImageStyle}>
          {data?.image?.url && (
            <img src={baseUrl + data?.image?.url} alt={data?.image?.altText} />
          )}
        </div>
      )}
      <div className={heroContentWrapper(pageTemplate)}>
        {pageTemplate !== "Homepage" &&
          pageTemplate !== "CustomerStoryPage" && (
            <div className={heroImage(pageTemplate)}>
              {data?.image?.url && (
                <img
                  src={baseUrl + data?.image?.url}
                  alt={data?.image?.altText}
                />
              )}
            </div>
          )}
        <div className={heroStyle}>
          <div
            className={heroTextContainer(
              pageTemplate,
              !!data?.image,
              data?.description,
              data?.subHeading,
            )}
          >
            <div className="relative mx-4 lg:mx-auto lg:w-full xl:w-container ">
              <div
                className={heroTextSubcontainer(pageTemplate, !!data?.image)}
              >
                <h1 className={heroHeadingStyle(pageTemplate)}>
                  {heading?.map((el, index) => (
                    <span
                      key={index}
                      className={
                        el.trim().length < 1 ? "h-[34px] lg:h-[64px]" : "h-auto"
                      }
                    >
                      {el.replace(/-/g, "\u2011")}
                    </span>
                  ))}
                </h1>
                {data?.subHeading && (
                  <div className={heroSubHeadingStyle(pageTemplate)}>
                    <div className="pt-[10px] text-xl text-gray-secondary xl:text-3xl">
                      {subHeading?.map((el, index) => (
                        <div
                          key={index}
                          className={
                            el.trim().length < 1
                              ? "h-[28px] lg:h-[36px]"
                              : "h-auto"
                          }
                        >
                          {el}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {data?.imageMobile?.url &&
                  pageTemplate !== "Homepage" &&
                  pageTemplate !== "CustomerStoryPage" && (
                    <div className="flex h-full w-full justify-center pt-5 lg:hidden">
                      <img
                        src={baseUrl + data?.imageMobile?.url}
                        alt={data?.imageMobile?.altText}
                      />
                    </div>
                  )}

                {data?.imageMobile?.url &&
                  pageTemplate === "CustomerStoryPage" && (
                    <div className="flex h-[300px] w-[500px] justify-end pt-5 lg:hidden">
                      <img
                        src={baseUrl + data?.imageMobile?.url}
                        alt={data?.imageMobile?.altText}
                      />
                    </div>
                  )}
                {data?.imageMobile?.url && pageTemplate === "Homepage" && (
                  <div className="absolute -top-[11%] z-10 ml-[33px] flex w-full justify-end lg:hidden">
                    <img
                      src={baseUrl + data?.imageMobile?.url}
                      alt={data?.imageMobile?.altText}
                    />
                  </div>
                )}
                {data?.description && (
                  <div className={heroSubHeadingStyle(pageTemplate)}>
                    <div className="pr-3 pt-5 font-roboto text-lg text-gray-secondary lg:pt-[30px] lg:text-lg">
                      {description?.map((el, index) => (
                        <div
                          className={
                            el.trim().length < 1
                              ? "h-[24px] lg:h-[28px]"
                              : "h-auto"
                          }
                          key={index}
                        >
                          {el}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {data?.priceLabel && (
                  <div className="relative z-20 pt-5 text-lg font-bold text-gray-secondary lg:pt-[30px] lg:text-xl">
                    {data?.priceLabel}
                  </div>
                )}
                {data?.price && (
                  <div className="relative z-20 mt-[4px] text-30 font-bold text-blue-dark xl:mb-[-40px] xl:text-50">
                    {getSymbolFromCurrency(data?.priceCurrency || "")}
                    {data?.price}
                  </div>
                )}
                {data?.links && data?.links?.length > 0 && (
                  <div className="relative z-20 flex w-auto pt-5 xl:pt-[50px]">
                    {data?.links?.map((l, index) => (
                      <div key={index}>
                        <LinkComp
                          linkUrl={l?.link?.url}
                          target={l?.link?.target}
                          label={l.link.label}
                          customStyle={
                            l?.color === "dark"
                              ? `mr-[30px] px-6 py-3 ${darkButtonStyle} `
                              : l?.color === "light"
                                ? `px-6 py-3 ${lightButtonStyle}`
                                : `mr-[30px] px-6 py-3 ${darkButtonStyle}`
                          }
                          isExternal={l?.link?.isExternal}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
